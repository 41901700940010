import React from 'react'

export default class ProfileOverallStats extends React.Component {
  render(){
    return(
      <ul className="top-level-stat-container">
        <li className="icon-stat-card stat-card">
          <div className="card-icon-container peach"><img src="/images/icons/follower.svg" /></div>
          <h3 className="big-stat js-follower-count">{this.props.profile.followers_count}</h3>
          <h6>Followers</h6>
        </li>
        <li className="icon-stat-card stat-card">
          <div className="card-icon-container matcha"><img src="/images/icons/fingerprint.svg" /></div>
          <h3 className="big-stat"><span className="js-engagement-percent-stat">{(this.props.profile.engagement_percent).toFixed(1)}</span>%</h3>
          <h6>Engagement</h6>
          <p className="sub-meta">average per post</p>
        </li>
        <li className="icon-stat-card stat-card">
          <div className="card-icon-container rose"><img src="/images/icons/comment-like.svg" /></div>
          <h3 className="big-stat"><span className="js-engagements-stat">{this.props.profile.engagement_total}</span></h3>
          <h6>Engagements</h6>
          <p className="sub-meta">per post likes + comments</p>
        </li>
        <li className="icon-stat-card stat-card">
          <div className="card-icon-container lavendar"><img src="/images/icons/touch.svg" /></div>
          <h3 className="big-stat">{this.props.profile.interactions_per_story}</h3>
          <h6>Interactions</h6>
          <p className="sub-meta">per story replies + taps back</p>
        </li>
        <li className="icon-stat-card stat-card">
          <div className="card-icon-container manila"><img src="/images/icons/arrow-out.svg" /></div>
          <h3 className="big-stat">{this.props.profile.website_clicks}</h3>
          <h6>Website clicks</h6>
          <p className="sub-meta">today</p>
        </li>
      </ul>
    )
  }
}