import React from 'react'

export default class ProfileDescription extends React.Component {
  render(){
    let websiteLink = null
    if (this.props.profile.website){
      websiteLink = <a href={this.props.profile.website} className="js-ig-website">{this.props.profile.website}</a>
    }
    return(
      <div className="ig-profile-container">
        <img src={this.props.profile.profile_picture_url} alt="Instagram avatar" className="ig-avatar-main js-ig-avatar-main" />
        <div className="ig-profile-details">
          <h2 className="ig-username">@<span>{this.props.profile.instagram_username}</span></h2>
          <h3 className="ig-name">{this.props.profile.name}</h3>
          <p className="ig-biography js-ig-biography">{this.props.profile.biography}</p>
          { websiteLink }
        </div>
      </div>
    )
  }
}