import React from 'react'

export default class CitiesLoading extends React.Component {
  render(){
    return(
      <>
        <span className="alert-text red">100 followers needed for insights</span>
        <li>
          <span className="stat-item-label">Los Angeles</span>
          <span className="stat-item-value">N/A</span>
        </li>
        <li>
          <span className="stat-item-label">Amsterdam</span>
          <span className="stat-item-value">N/A</span>
        </li>
        <li>
          <span className="stat-item-label">Tokyo</span>
          <span className="stat-item-value">N/A</span>
        </li>
      </>
    );
  }
}