import React from 'react'

export default class InsightsAudienceCountriesContainer extends React.Component {
  render(){
    let insightHash = this.props.insights ? this.props.insights.insight_hash : null
    let countryView = (
      <>
        <li>
          <span className="stat-item-label">United States</span>
          <span className="stat-item-value">N/A</span>
        </li>
        <li>
          <span className="stat-item-label">Netherlands</span>
          <span className="stat-item-value">N/A</span>
        </li>
        <li>
          <span className="stat-item-label">Japan</span>
          <span className="stat-item-value">N/A</span>
        </li>
      </>
    )
    if (insightHash) {
      const countryItems = (
        insightHash.top_countries.map((country_hash,index) =>
          <li key={index}>
            <span className="stat-item-label">{country_hash[0]}</span>
            <span className="stat-item-value">{Math.round(country_hash[2])}%</span>
          </li>
        )
      )
      countryView = (
        <>
          { countryItems }
          <li>
            <span className="stat-item-label">Other</span>
            <span className="stat-item-value">{Math.round(insightHash.total_other_percent)}%</span>
          </li>
        </>
      )
    }
    return(
      <div className="stat-card">
        <div className="card-icon-container peach"><img src="/images/icons/country.svg" /></div>
        <h6>Audience Countries</h6>
        <ul className="audience-country-container js-audience-country-container">
          <span className={`alert-text red ${this.props.insights ? 'hidden' : ''}`}>100 followers needed for insights</span>
          { countryView }
        </ul>
      </div>
    )
  }
}