import React from 'react'
import CitiesLoading from './cities_loading'
import CityList from './city_list'

export default class InsightsAudienceCitiesContainer extends React.Component {
  render(){
    let insightHash = this.props.insights?.insight_hash // using babel optionals
    return(
      <div className="stat-card">
        <div className="card-icon-container matcha"><img src="/images/icons/city.svg" /></div>
        <h6>Audience Cities</h6>
        <ul className="audience-city-container js-audience-city-container">
          { insightHash ? <CityList insights={insightHash} /> : <CitiesLoading /> }
        </ul>
      </div>
    )
  }
}