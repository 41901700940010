import React from 'react';
import PostsOverallStats from './posts_overall_stats';
import ProfileOverallStats from './profile_overall_stats';
import MediaContainer from './media_container';
import InsightsAudienceCountriesContainer from './insights_audience_countries_container';
import InsightsAudienceCitiesContainer from './insights_audience_cities_container';
import InsightsAudienceGenderContainer from './insights_audience_gender_container';
import InsightsAudienceAgeContainer from './insights_audience_age_container';
import StoriesOverallStats from './stories_overall_stats';
import ProfileDescription from './profile_description';
// import DashboardGraphContainer from './dashboard_graph_container';

export default class Dashboard extends React.Component {

  filterMedia = (media, mediaFilter) => {
    let result = []
    media.forEach((item) => {
      if (mediaFilter === 'posts') {
        if (!item.is_story) {
          result.push(item)
        }
      }
      if (mediaFilter === 'stories') {
        if (item.is_story) {
          result.push(item)
        }
      }
    })
    return result
  }

  sortMedia = (media, mediaFilter) => {
    let result = []
    if (mediaFilter === 'stories') {
      result = media.sort(function (a, b) {
        const aImpressionCount = a.impressions_count ? a.impressions_count : 0
        const bImpressionCount = b.impressions_count ? b.impressions_count : 0
        return bImpressionCount - aImpressionCount
      })
    }
    if (mediaFilter === 'posts') {
      result = media.sort(function (a, b) {
        const aLikeCount = a.likes_count ? a.likes_count : 0
        const bLikeCount = b.likes_count ? b.likes_count : 0
        const aCommentsCount = a.comments_count ? a.comments_count : 0
        const bCommentsCount = b.comments_count ? b.comments_count : 0
        const aEng = aLikeCount + aCommentsCount
        const bEng = bLikeCount + bCommentsCount
        return bEng - aEng
      })
    }
    return result
  }

  filterAndSortMedia = (media, mediaFilter) => {
    const filteredMediaResult = this.filterMedia(media, mediaFilter)
    const sortedMediaResult = this.sortMedia(filteredMediaResult, mediaFilter)
    return sortedMediaResult
  }

  render() {
    const profile = this.props.data.profile ? this.props.data.profile : null
    const insights = this.props.data.insights ? this.props.data.insights : null
    const media = this.props.data.media ? this.props.data.media : null
    const filteredMedia = this.props.data.media ? this.filterAndSortMedia(media, this.props.mediaFilter) : null

    let audienceCountryInsights = null
    let audienceCityInsights = null
    let audienceGenderInsights = null
    let audienceAgeInsights = null
    if (insights) {
      insights.forEach(function (insight) {
        switch (insight.insight_type) {
          case 'audience_country':
            audienceCountryInsights = insight
            break
          case 'audience_city':
            audienceCityInsights = insight
            break
          case 'audience_gender':
            audienceGenderInsights = insight
            break
          case 'audience_age':
            audienceAgeInsights = insight
            break
          default:
            return
        }
      })
    }

    //TODO: Change logout to just logout, rather than reseting their acc credentials
    return (
      <div className={`dashboard logged-in-container js-logged-in-container ${this.props.data ? 'active' : ''}`}>
        <nav className="logged-in-nav">
          <h3>MightyCheck</h3>
          <a href="#" className="btn btn-primary nav-logout" onClick={this.props.handleFbLogout}>Logout</a>
        </nav>
        <ProfileDescription profile={profile} />
        <div className="ig-profile-stats-container">
          <ProfileOverallStats profile={profile} />
          {/* <DashboardGraphContainer /> */}
          <div className="row">
            <div className="col-sm-4">
              <PostsOverallStats profile={profile} />
            </div>
            <div className="col-sm-4">
              <StoriesOverallStats profile={profile} />
            </div>
            <div className="col-sm-4">
              <InsightsAudienceAgeContainer insights={audienceAgeInsights} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <InsightsAudienceCountriesContainer insights={audienceCountryInsights} />
            </div>
            <div className="col-sm-4">
              <InsightsAudienceCitiesContainer insights={audienceCityInsights} />
            </div>
            <div className="col-sm-4">
              <InsightsAudienceGenderContainer insights={audienceGenderInsights} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              {/* { renderLineChart } */}
            </div>
          </div>
        </div>
        <MediaContainer
          data={this.props.data}
          media={filteredMedia}
          mediaFilter={this.props.mediaFilter}
          mentions={this.props.data.mentions}
          followers_count={profile.followers_count}
          handleFilterClick={this.props.handleFilterClick}
          requestConversations={this.props.requestConversations}
          conversations={this.props.conversations}
          isLoadingConversations={this.props.isLoadingConversations}
          conversationsLoadCount={this.props.conversationsLoadCount}
          handleConversationClick={this.props.handleConversationClick}
          handleGrantMessagePermissionsClick={this.props.handleGrantMessagePermissionsClick} />
      </div>
    );
  }
}
