import React from 'react'

export default class InsightsAudienceGenderContainer extends React.Component {
  render(){
    let insightHash = this.props.insights ? this.props.insights.insight_hash : null
    let genderView = (
      <>
        <li><span className="stat-item-label">Female</span><span className="stat-item-value">N/A</span></li>
        <li><span className="stat-item-label">Male</span><span className="stat-item-value">N/A</span></li>
        <li><span className="stat-item-label">Undisclosed</span><span className="stat-item-value">N/A</span></li>
      </>
    )
    if (insightHash){
      genderView = (
        <>
          <li>
            <span className="stat-item-label">Female</span>
            <span className="stat-item-value">{insightHash["F"].percent}%</span>
          </li>
          <li>
            <span className="stat-item-label">Male</span>
            <span className="stat-item-value">{insightHash["M"].percent}%</span>
          </li>
          <li>
            <span className="stat-item-label">Undisclosed</span>
            <span className="stat-item-value">{insightHash["U"].percent}%</span>
          </li>
        </>
      )
    }
    return(
      <div className="stat-card">
        <div className="card-icon-container rose"><img src="/images/icons/female.svg" /></div>
        <h6>Audience Gender</h6>
        <ul className="audience-gender-container js-audience-gender-container">
          <span className={`alert-text red ${this.props.insights ? 'hidden' : ''}`}>100 followers needed for insights</span>
          { genderView }
        </ul>
      </div>
    )
  }
}