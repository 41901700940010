import React from 'react'

export default class StoriesOverallStats extends React.Component {
  render(){
    return(
      <div className="stat-card stat-list-card">
        <div className="stat-card-header">
          <div className="card-icon-container lavendar small-icon"><img src="/images/icons/mobile.svg" /></div>
          <h6>Stories</h6>
        </div>
        <ul>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Stories per day</span>
            <span className="stat-item-value">
              {this.props.profile.stories_per_day ? this.props.profile.stories_per_day : 'N/A'}
            </span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Interactions per story</span>
            <span className="stat-item-value">
              {this.props.profile.interactions_per_story ? this.props.profile.interactions_per_story : 'N/A'}
            </span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Exits per story</span>
            <span className="stat-item-value">
              {this.props.profile.exits_per_story ? this.props.profile.exits_per_story : 'N/A'}
            </span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Replies per story</span>
            <span className="stat-item-value">
              {this.props.profile.replies_per_story ? this.props.profile.replies_per_story : 'N/A'}
            </span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Impressions per story</span>
            <span className="stat-item-value">
              {this.props.profile.impressions_per_story ? this.props.profile.impressions_per_story : 'N/A'}
            </span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Reach per story</span>
            <span className="stat-item-value">
              {this.props.profile.reach_per_story ? this.props.profile.reach_per_story : 'N/A'}
            </span>
          </li>
        </ul>
      </div>
    )
  }
}