import React from 'react'

export default class PostsOverallStats extends React.Component {
  render(){
    return(
      <div className="stat-card stat-list-card">
        <div className="stat-card-header">
          <div className="card-icon-container rose small-icon"><img src="/images/icons/laptop.svg" /></div>
          <h6>Posts</h6>
          <span className="js-post-media-count">{this.props.profile.media_count}</span>
        </div>
        <ul>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Posts per day</span>
            <span className="stat-item-value js-posts-per-day">{this.props.profile.posts_per_day}</span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Likes per post</span>
            <span className="stat-item-value js-post-likes-per-post">{this.props.profile.likes_per_post}</span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Comments per post</span>
            <span className="stat-item-value js-post-comments-per-post">{this.props.profile.comments_per_post}</span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Saves per post</span>
            <span className="stat-item-value js-post-saves-per-post">{this.props.profile.saves_per_post}</span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Impressions per post</span>
            <span className="stat-item-value js-post-impressions-per-post">{this.props.profile.impressions_per_post}</span>
          </li>
          <li className="stat-list-card-line-item">
            <span className="stat-item-label">Reach per post</span>
            <span className="stat-item-value js-post-reach-per-post">{this.props.profile.reach_per_post}</span>
          </li>
        </ul>
      </div>
    );
  }
}
