import React from 'react'

export default class CityList extends React.Component {
  render(){
    const insights = this.props.insights
    const cityItems = (
      insights.top_cities.map((city, index)=>
        <li key={index}>
          <span className="stat-item-label">{city[0].split(",")[0]}</span>
          <span className="stat-item-value">{Math.round(city[2])}%</span>
        </li>
      )
    )
    const cityView = (
      <>
        { cityItems }
        <li>
          <span className="stat-item-label">Other</span>
          <span className="stat-item-value">{Math.round(insights.total_other_percent)}%</span>
        </li>
      </>
    )
    return(
      <>
        { cityView }
      </>
    );
  }
}