import React from 'react'

export default class InsightsAudienceAgeContainer extends React.Component {
  render(){
    let insightHash = this.props.insights ? this.props.insights.insight_hash : null
    //TODO: Change this to key value iteration
    let ageInsightView = (
      <>
        <li>
          <span className="stat-item-label">13-17</span>
          <span className="stat-item-value">{insightHash ? insightHash["13-17"]["percent"] + "%" : "N/A"}</span>
        </li>
        <li>
          <span className="stat-item-label">18-24</span>
          <span className="stat-item-value">{insightHash ? insightHash["18-24"]["percent"] + "%" : "N/A"}</span>
        </li>
        <li>
          <span className="stat-item-label">25-34</span>
          <span className="stat-item-value">{insightHash ? insightHash["25-34"]["percent"] + "%" : "N/A"}</span>
        </li>
        <li>
          <span className="stat-item-label">35-44</span>
          <span className="stat-item-value">{insightHash ? insightHash["35-44"]["percent"] + "%" : "N/A"}</span>
        </li>
        <li>
          <span className="stat-item-label">45+</span>
          <span className="stat-item-value">{insightHash ? insightHash["45+"]["percent"] + "%" : "N/A"}</span>
        </li>
      </>
    );
    
    return(
      <div className="stat-card age-card">
        <div className="card-icon-container manila"><img src="/images/icons/age.svg" /></div>
        <h6>Audience Age</h6>
        <ul className="audience-age-container js-audience-age-container">
          <span className={`alert-text red ${this.props.insights ? 'hidden' : ''}`}>100 followers needed for insights</span>
          { ageInsightView }
        </ul>
      </div>
    )
  }
}